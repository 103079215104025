import React from "react";

import { base, buttonContainer, imageContainer } from "./VerticalBanner.module.scss";

import Button from "../components/Button";
import { combine, getClassNameModifiers } from "../utils/classNames";

const VerticalBanner = props => {
  const { vertical, heading, subHeading, imageSrc, backgroundImgSrc, noCtaButtons } = props;

  const modifiers = getClassNameModifiers(props, ["noCtaButtons"]);

  return (
    <div className={combine(base, modifiers)}>
      <h4>{vertical}</h4>
      <h1>{heading}</h1>
      <p>{subHeading}</p>
      {!noCtaButtons && (
        <div className={buttonContainer}>
          <Button color="navy" href="/book-a-demo">
            Start today
          </Button>
          <Button isSecondary color="navy" href="/book-a-demo">
            Schedule a demo
          </Button>
        </div>
      )}
      <img src={backgroundImgSrc} alt="" />
      <img src={imageSrc} alt="" />
    </div>
  );
};

export default VerticalBanner;
