import React from "react";

import { base, checklist } from "./VerticalInfoBanner.module.scss";

import Checklist from "./Checklist";

const VerticalInfoBanner = props => {
  const { heading, subHeading, checklistItems } = props;

  return (
    <section className={base}>
      <h2>{heading}</h2>
      <p>{subHeading}</p>
      <Checklist className={checklist} items={checklistItems} />
      <img src="/images/cloud-contact.svg" alt="" />
      <img src="/images/your-business.svg" alt="" />
    </section>
  );
};

export default VerticalInfoBanner;
