import React from "react";

import { base } from "./VerticalCaseStudySection.module.scss";

import CaseStudy from "./CaseStudy";

const VerticalCaseStudySection = props => {
  const { caseStudy } = props;

  return (
    <section className={base}>
      <CaseStudy
        color="navy"
        title={caseStudy.featured.headline}
        body={caseStudy.featured.extract}
        author={caseStudy.featured.author}
        authorDescription={caseStudy.authorRole}
        url={caseStudy.slug}
        imageSrc={caseStudy.featureImages[0].src}
        imageAlt={caseStudy.featureImages[0].alt}
        eyebrowLabel="Case study"
        noButton
      />
    </section>
  );
};

export default VerticalCaseStudySection;
