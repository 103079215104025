import React from "react";

import { base, partnersGraphic } from "./VerticalPartnersSection.module.scss";

import AnimatedSvg from "../components/AnimatedSvg";

import { useMediaQuery } from "../utils/hooks";

const VerticalPartnersSection = props => {
  const { heading } = props;

  const isMobile = useMediaQuery("(max-width: 575px)");

  return (
    <section className={base}>
      <h2>{heading}</h2>
      <AnimatedSvg
        className={partnersGraphic}
        graphics={[
          { image: "/images/window@3x.png", x: 0, y: 0, delay: 0.0, height: isMobile ? 14.75 : 32.5 },
          {
            image: "/images/brands@3x.png",
            x: isMobile ? 1.102 : 2.425,
            y: isMobile ? 2.245 : 4.938,
            delay: 0.2,
            height: isMobile ? 9.091 : 20.0,
          },
        ]}
      />
    </section>
  );
};

export default VerticalPartnersSection;
