import React from "react";

import { base, logoCarousel } from "./VerticalNetworkSection.module.scss";

import LogoCarousel from "./LogoCarousel";

const VerticalNetworkSection = props => {
  const { heading, logos, height } = props;

  return (
    <section className={base}>
      <h2>{heading}</h2>
      <LogoCarousel
        className={logoCarousel}
        logos={logos}
        height={height}
        backgroundColor="charcoal-15"
      />
    </section>
  );
};

export default VerticalNetworkSection;
